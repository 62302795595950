//
// COMPONENTS.AVATAR
//



.c-avatar {}

.c-avatar__img {
    border-radius: 50%;
    width: 11rem;
}



@include media-breakpoint-up(md) {

    .c-avatar__img {
        width: 14rem;
    }

}



@include media-breakpoint-up(lg) {

    .c-avatar__img {
        //width: 15rem;
        width: 100%;
    }

}