//
// TRUMPS.SPACING
//



.u-pt-0 {
    padding-top: 0 !important;
}

.u-pb-0 {
    padding-bottom: 0 !important;
}