//
// COMPONENTS.SECTION
//



.c-section {}

.c-section__header-bg,
.c-section__content-bg {
    display: none;
}

.c-section__header {
    padding: $section-header-padding;
}

.c-section__content {
    padding: $section-content-padding;
}

.c-section__full-top-space {
    padding-top: $section-padding-y;
}

.c-section__full-bottom-space {
    padding-bottom: $section-padding-y;
}



@include media-breakpoint-up(md) {

    .c-section__header {
        padding: $section-header-padding--md;
    }

    .c-section--header .c-section__header {
        padding-bottom: 0;
    }

    .c-section__content {
        padding: $section-content-padding--md;
    }

    .c-section__full-top-space {
        padding-top: $section-padding-y--md;
    }

    .c-section__full-bottom-space {
        padding-bottom: $section-padding-y--md;
    }

}



@include media-breakpoint-up(lg) {

    .c-section {
        position: relative;
    }

    .c-section__container {
        z-index: 2;
        position: relative;
    }

    .c-section__container {
        display: flex;
    }

    .c-section__header {
        padding: $section-header-padding--lg;
        width: 30%;
        text-align: right;
    }

    .c-section__content {
        padding: $section-content-padding--lg;
        //margin-bottom: -$content-margin-bottom--lg;
        width: 70%;
    }

    .c-section__full-top-space {
        padding-top: $section-padding-y--lg;
    }

    .c-section__full-bottom-space {
        padding-bottom: $section-padding-y--lg;
    }

    // Background extension

    .c-section__header-bg,
    .c-section__content-bg {
        display: block;
        position: absolute;
        height: 100%;
        width: 50%;
        z-index: 1;
    }

    .c-section__content-bg {
        right: 0;
    }

}