//
// MAIN
//
// Here we import all of our modules and also set configuration variables for an
// individual module, right before its imported.
//



////////////////////////////////////////////////////////////////////////////////
// Settings
////////////////////////////////////////////////////////////////////////////////

@import "settings.defaults";

$revo-root-font-size:   $root-font-size;
$revo-base-font-size:   $base-font-size;
//$revo-base-color:       $text-color;
$revo-base-line-height: $base-line-height;
//$revo-base-bg:          $base-bg;
$revo-spacer:           $spacer;
$revo-spacer-x:         $spacer-x;
$revo-spacer-y:         $spacer-y;
@import "../bower_components/revolt.settings.defaults/settings.defaults";

$revo-spacers: $spacers;
@import "../bower_components/revolt.settings.spacing/settings.spacing";

@import "settings.responsive";



////////////////////////////////////////////////////////////////////////////////
// Tools
////////////////////////////////////////////////////////////////////////////////

@import "../bower_components/revolt.tools.functions/tools.functions";

//@import "../bower_components/revolt.tools.spacing/tools.spacing";
@import "tools.spacing";

@import "../bower_components/revolt.tools.responsive/tools.responsive";

@import "tools.reset-text";



////////////////////////////////////////////////////////////////////////////////
// Generic
////////////////////////////////////////////////////////////////////////////////

@import "../bower_components/revolt.generic.normalize/generic.normalize";

@import "../bower_components/revolt.generic.reset/generic.reset";

@import "../bower_components/revolt.generic.box-sizing/generic.box-sizing";

$revo-vertical-space: spacer(text);
@import "../bower_components/revolt.generic.vertical-spacing/generic.vertical-spacing";



////////////////////////////////////////////////////////////////////////////////
// Base
////////////////////////////////////////////////////////////////////////////////

$revo-font-family: $base-font-family;
@import "../bower_components/revolt.base.root/base.root";
@import "base.root";

@import "base.headings";

@import "base.links";

//@import "../bower_components/revolt.base.figure/base.figure";

$revo-hr-size: 2px;
@import "../bower_components/revolt.base.hr/base.hr";

@import "../bower_components/revolt.base.lists/base.lists";

@import "../bower_components/revolt.base.responsive-images/base.responsive-images";



////////////////////////////////////////////////////////////////////////////////
// Objects
////////////////////////////////////////////////////////////////////////////////

@import "../bower_components/revolt.objects.list-bare/objects.list-bare";

@import "../bower_components/revolt.objects.list-inline/objects.list-inline";

$revo-media-gutter: 1rem;
//@import "../bower_components/revolt.objects.media/objects.media";
@import "objects.media";

@import "objects.contrainer";

@import "objects.content";

@import "objects.grid";

//@import "objects.link-container";



////////////////////////////////////////////////////////////////////////////////
// Components
////////////////////////////////////////////////////////////////////////////////

@import "components.main-container";

@import "components.preloader";

@import "components.section";

@import "components.header";

@import "components.footer";

@import "components.brand";

@import "components.avatar";

@import "components.intro";

@import "components.number";

@import "components.deco-line";

@import "components.timeline";

@import "components.work";

@import "components.social-button";

@import "components.social-buttons";

@import "components.image-overlay";

@import "components.clients";



////////////////////////////////////////////////////////////////////////////////
// Plugins
////////////////////////////////////////////////////////////////////////////////

//@import "../bower_components/font-awesome/scss/font-awesome";

@import "plugins.font-awesome";

@import "plugins.nivo-lightbox";



////////////////////////////////////////////////////////////////////////////////
// Trumps
////////////////////////////////////////////////////////////////////////////////

//@import "../bower_components/revolt.trumps.spacing/trumps.spacing";

//@import "../bower_components/revolt.trumps.spacing-responsive/trumps.spacing-responsive";

//@import "trumps.links";

//@import "trumps.typography";

@import "trumps.spacing";

@import "trumps.display";



////////////////////////////////////////////////////////////////////////////////
// Scopes
////////////////////////////////////////////////////////////////////////////////

//@import "scopes.demo";