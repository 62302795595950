//
// COMPONENTS.SOCIAL-BUTTON
//



.c-social-button {
    display: block;
    text-align: center;

    width: 33px;
    height: 33px;
    //padding-top: 8px;
    font-size: .8rem !important;
    line-height: 3.1 !important;
    border-radius: 2px;
}



@include media-breakpoint-up(xl) {

    .c-social-button {
        width: 38px;
        height: 38px;
        //padding-top: 8px;
        font-size: 1rem !important;
        line-height: 2.8 !important;
        border-radius: 3px;
    }

}